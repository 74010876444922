import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableFooter,
    TableCell,
    TableBody,
    MenuItem,
    Icon,
    Button,
    Menu,
    Table,
    Message,
    MessageHeader
} from 'semantic-ui-react'
import { olvaExportToXSL } from './OlvaExportXSL'
import { dinsidesExportToXSL } from './DinsidesExportXSL'
import { generateVoucherPDF } from './PDFLabelExport'
import { generalExportToXSL } from './generalExportToXSL'
import TrackingCodeEdit from '../Modal/TrackingCodeEditModal'
import { ANULL_VALUE, CODE_ASSIGN_VALUE, PENDING_VALUE, TRACKING_CODE_FIELD_ID } from '../Constants'

const OLVA_CHECKBOX_ID = "OLVA_CHECKBOX_ID"
const DINSIDES_CHECKBOX_ID = "DINSIDES_CHECKBOX_ID"
const DOCUMENT_FIELD_ID = "DOCUMENT_FIELD_ID"
const FROM_DATE_FIELD_ID = "FROM_DATE_FIELD_ID"
const TO_DATE_FIELD_ID = "TO_DATE_FIELD_ID"

const ItemTable = ({ salesData, filters, currentPage, setCurrentPage, totalPages, loadCurrentPage }) => {

    const filteredArray = salesData.filter((sale) => {
        if (filters.length == 0) { return true }

        let isValid = false;
        let isValidFromDate = null;
        let isValidToDate = null;

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            switch (filter.id) {
                case OLVA_CHECKBOX_ID:
                    isValid = filter.value == sale.shipmentType;
                    break;
                case DINSIDES_CHECKBOX_ID:
                    isValid = filter.value == sale.shipmentType;
                    break;
                case DOCUMENT_FIELD_ID:
                    isValid = filter.value == sale.documentNumber;
                    break;
                case FROM_DATE_FIELD_ID:
                    isValidFromDate = false;
                    isValidFromDate = new Date(sale.registrationDate) >= new Date(filter.value);
                    isValid = new Date(sale.registrationDate) >= new Date(filter.value);
                    break;
                case TO_DATE_FIELD_ID:
                    isValidToDate = false;
                    isValidToDate = new Date(sale.registrationDate) <= new Date(filter.value);
                    isValid = new Date(sale.registrationDate) <= new Date(filter.value);
                    break;
                case TRACKING_CODE_FIELD_ID:
                    if(filter.value === PENDING_VALUE && sale.remito === PENDING_VALUE) {
                        console.log({"filter.value": filter.value, "sale.remito": sale.remito, x: "x1"})
                        isValid = true
                    } else if(filter.value === ANULL_VALUE && sale.remito === ANULL_VALUE) {
                        console.log({"filter.value": filter.value, "sale.remito": sale.remito, x: "x2"})
                        isValid = true
                    } else if(filter.value === CODE_ASSIGN_VALUE && sale.remito !== PENDING_VALUE && sale.remito !== ANULL_VALUE){
                        console.log({"filter.value": filter.value, "sale.remito": sale.remito, x: "x3"})
                        isValid = true
                    } else {
                        console.log({"filter.value": filter.value, "sale.remito": sale.remito, x: "x4"})
                        isValid = false
                    }
                    break;
                default:
                    break;
            }
        }

        if (isValidFromDate == null && isValidToDate == null) {
            return isValid;
        } else {
            if (isValidFromDate != null) {
                return isValid && isValidFromDate;
            } else if (isValidToDate != null) {
                return isValid && isValidToDate;
            }
        }
    });

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        filteredArray.length > 0 ?
            <div style={{ overflowX: 'auto' }}>
                <Table  singleLine>
                    <TableHeader>
                        <TableHeaderCell colSpan='23'>
                            <Button size='large' onClick={() => { generalExportToXSL(filteredArray) }}>Exportar</Button>
                            <Button size='large' onClick={() => { olvaExportToXSL(filteredArray) }}>Envios Olva</Button>
                            <Button size='large' onClick={() => { dinsidesExportToXSL(filteredArray) }}>Envios Dinsides</Button>
                            <Button size='large' onClick={() => { generateVoucherPDF(filteredArray) }}>Imprimir Rotulado</Button>
                        </TableHeaderCell>
                        <TableRow>
                            <TableHeaderCell>Código de seguimiento</TableHeaderCell>
                            <TableHeaderCell>Canal de Venta</TableHeaderCell>
                            <TableHeaderCell>Codigo de Cliente</TableHeaderCell>
                            <TableHeaderCell>Es Preventa</TableHeaderCell>
                            <TableHeaderCell>Fecha/Hora Registro</TableHeaderCell>
                            <TableHeaderCell>Fecha/Hora Deposito</TableHeaderCell>
                            <TableHeaderCell>Banco</TableHeaderCell>
                            <TableHeaderCell>Monto</TableHeaderCell>
                            <TableHeaderCell>Codigo de Boleta</TableHeaderCell>
                            <TableHeaderCell>Tipo de Documento</TableHeaderCell>
                            <TableHeaderCell>Documento</TableHeaderCell>
                            <TableHeaderCell>Nombre</TableHeaderCell>
                            <TableHeaderCell>Apellido Paterno</TableHeaderCell>
                            <TableHeaderCell>Apellido Materno</TableHeaderCell>
                            <TableHeaderCell>Departamento</TableHeaderCell>
                            <TableHeaderCell>Provincia</TableHeaderCell>
                            <TableHeaderCell>Distrito</TableHeaderCell>
                            <TableHeaderCell>Direccion</TableHeaderCell>
                            <TableHeaderCell>Referencia</TableHeaderCell>
                            <TableHeaderCell>N De Contacto</TableHeaderCell>
                            <TableHeaderCell>Correo</TableHeaderCell>
                            <TableHeaderCell>Tipo de Envio</TableHeaderCell>
                            <TableHeaderCell>Tipo de Entrega</TableHeaderCell>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {
                            filteredArray.map((sale) => {
                                const s = sale.getFormatToDisplay();
                                return (
                                    <TableRow>
                                        <TableCell>
                                            <span style={{ "marginRight": "10px" }}>
                                                {s.remito}
                                            </span>
                                            <span>
                                                <TrackingCodeEdit loadCurrentPage={loadCurrentPage} shipmentData={sale} />
                                            </span>
                                        </TableCell>
                                        <TableCell>{s.saleChannel}</TableCell>
                                        <TableCell>{s.clientCode}</TableCell>
                                        <TableCell>{s.isPreSale}</TableCell>
                                        <TableCell>{s.registrationDate}</TableCell>
                                        <TableCell>{s.depositDate}</TableCell>
                                        <TableCell>{s.bank}</TableCell>
                                        <TableCell>{s.amount}</TableCell>
                                        <TableCell>{s.boletaCode}</TableCell>
                                        <TableCell>{s.documentType}</TableCell>
                                        <TableCell>{s.documentNumber}</TableCell>
                                        <TableCell>{s.firstName}</TableCell>
                                        <TableCell>{s.lastName}</TableCell>
                                        <TableCell>{s.secondLastName}</TableCell>
                                        <TableCell>{s.locationDepartment}</TableCell>
                                        <TableCell>{s.locationProvince}</TableCell>
                                        <TableCell>{s.locationDistrict}</TableCell>
                                        <TableCell>{s.locationAddress}</TableCell>
                                        <TableCell>{s.locationReference}</TableCell>
                                        <TableCell>{s.contactNumber}</TableCell>
                                        <TableCell>{s.email}</TableCell>
                                        <TableCell>{s.shipmentType}</TableCell>
                                        <TableCell>{s.deliverType}</TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableHeaderCell colSpan='23'>
                                <Menu floated='right' pagination>
                                    <MenuItem as='a' icon onClick={goToPreviousPage} disabled={currentPage === 1}>
                                        <Icon name='chevron left' />
                                    </MenuItem>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <MenuItem
                                            as='a'
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() => goToPage(index + 1)}
                                        >
                                            {index + 1}
                                        </MenuItem>
                                    ))}
                                    <MenuItem as='a' icon onClick={goToNextPage} disabled={currentPage === totalPages}>
                                        <Icon name='chevron right' />
                                    </MenuItem>
                                </Menu>

                            </TableHeaderCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            :
            <Message>
                <MessageHeader>No se encontraron Datos</MessageHeader>
                <p>No hay datos que mostrar o ajuste los filtros si estan seleccionados</p>
            </Message>
    )
}

export default ItemTable
