import * as XLSX from 'xlsx';

// Función para exportar los datos
export const olvaExportToXSL = (data) => {
    // Definir algunos datos usando la clase DataModel

    // Convertir los objetos a un formato de array de objetos plano
    const formattedData = data.map((item) => ({
        // "Tipo de Entrega": "",
        "Tipo de Entrega": item.deliverType,
        "Departamento": item.locationDepartment,
        "Provincia": item.locationProvince,
        "Distrito": item.locationDistrict,
        "Dirección": item.locationAddress,
        "Tienda": "",
        "Referencia": item.locationReference,
        "Tipo Empaque": "Paquete",
        "Tipo Artículo": "ROPA Y ACCESORIOS",
        "Descripción de Artículo": "Ropa",
        "Valor del Envío (S/.)": "",
        "Peso (kg)": "",
        "Largo (cm)": "",
        "Ancho (cm)": "",
        "Alto (cm)": "",
        "¿Retono de Cargo?": "",
        "# Folios": "",
        "Tipo Documento": item.documentType,
        "Nro DNI/RUC/CE": item.documentNumber,
        "Celular": item.contactNumber,
        "Razón Social": "",
        "Contacto": "",
        "Nombres": item.firstName,
        "Apellido Paterno": item.lastName,
        "Apellido Materno": item.secondLastName
    }));

    // Crear una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

    // Exportar el archivo Excel
    XLSX.writeFile(workbook, 'Envios_Olva.xlsx');
};