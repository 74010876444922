

export const ShipmentDataBody = (data) => {

const { formData, saleAmount, shpmtDocumentNumber, shpmtContactNumber, isPreSale } = data;


    return {
        depositDate: formData.salePaymentDate,
        bank: formData.saleBankName,
        depositAmount: saleAmount,
        isPreSale: isPreSale,
        clientCode: formData.clientCode,
        saleChannel: formData.salesChannel,
        remito: formData.courierReference,               //<- CHECK
        boletaCode: formData.saleInvoiceCode,
        shipmentType: formData.courierShippmentType,  //<- CHECK
        deliverType: formData.courierAddress,           //<- CHECK
        locationDepartment: formData.shpmtRegion,
        locationProvince: formData.shpmtProvince,
        locationDistrict: formData.shpmtDistrict,
        locationAddress: formData.shpmtAddress,
        locationReference: formData.shpmtReference,
        documentType: formData.shpmtDocumentType,
        documentNumber: shpmtDocumentNumber,
        contactNumber: shpmtContactNumber,
        email: formData.shpmtEmail,
        firstName: formData.shpmtName,
        lastName: formData.shpmtPaternalSurname,
        secondLastName: formData.shpmtMaternalSurname,
        saleCoordinates: "",
        productDetail: "",
        saleType: ""
      }
}