import React, { useState, useEffect } from 'react';
import { FormField, Button, FormGroup, Form, Input, Grid, Select, Image, Checkbox, Message, MessageHeader } from 'semantic-ui-react'
import './CreateForm.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ShipmentDataBody } from '../Networking/v1/ShipmentDataBody';
import { createShipmentData, CustomError, getDistrictsFromProvince, getProvincesFromRegion, getRegions, getSaleWithCode } from '../Networking/v1/endpoints';
import { useNavigate } from 'react-router-dom';


const SALES_CHANNEL_WEB = "Web"
const SALES_CHANNEL_NETWORK = "Redes"

const DOCUMENT_TYPE_DNI = "DNI"
const DOCUMENT_TYPE_CE = "CARNE EXTRANJERIA"

const PICKUP_AT_STORE = "Recojo en tienda"
const DELIVERY_TO_ADDRESS = "Entrega a domicilo"

const BANK_YAPE = "Yape"
const BANK_PLIN = "Plin"
const BANK_BCP = "BCP"
const BANK_BBVA = "BBVA"
const BANK_INTERBANK = "INTERBANK"
const BANK_MERCADOPAGO = "MERCADOPAGO"


const salesChannelOptions = [
    { key: SALES_CHANNEL_WEB, text: SALES_CHANNEL_WEB, value: SALES_CHANNEL_WEB },
    { key: SALES_CHANNEL_NETWORK, text: SALES_CHANNEL_NETWORK, value: SALES_CHANNEL_NETWORK }
]

const kindDeliveryOptions = [
    { key: PICKUP_AT_STORE, text: PICKUP_AT_STORE, value: PICKUP_AT_STORE },
    { key: DELIVERY_TO_ADDRESS, text: DELIVERY_TO_ADDRESS, value: DELIVERY_TO_ADDRESS }
]

const bankOptions = [
    { key: BANK_YAPE, text: BANK_YAPE, value: BANK_YAPE },
    { key: BANK_PLIN, text: BANK_PLIN, value: BANK_PLIN },
    { key: BANK_BCP, text: BANK_BCP, value: BANK_BCP },
    { key: BANK_BBVA, text: BANK_BBVA, value: BANK_BBVA },
    { key: BANK_INTERBANK, text: BANK_INTERBANK, value: BANK_INTERBANK },
    { key: BANK_MERCADOPAGO, text: BANK_MERCADOPAGO, value: BANK_MERCADOPAGO }
]

const shpmtDocumentTypeOptions = [
    {
        key: DOCUMENT_TYPE_DNI,
        text: DOCUMENT_TYPE_DNI,
        value: DOCUMENT_TYPE_DNI
    },
    {
        key: DOCUMENT_TYPE_CE,
        text: DOCUMENT_TYPE_CE,
        value: DOCUMENT_TYPE_CE
    }
]

const courierShippmentTypeOptions = [
    { key: 'OLVA', text: 'OLVA', value: 'OLVA' },
    { key: 'DINSIDES', text: 'DINSIDES', value: 'DINSIDES' },

]


const intialValues = {
    salesChannel: '',
    clientCode: '',
    salePaymentDate: '',
    saleBankName: '',
    saleInvoiceCode: '',
    shpmtDocumentType: '',
    shpmtName: '',
    shpmtPaternalSurname: '',
    shpmtMaternalSurname: '',
    shpmtRegion: '',
    shpmtProvince: '',
    shpmtDistrict: '',
    shpmtAddress: '',
    shpmtReference: '',
    shpmtEmail: '',
    courierShippmentType: '',
    courierAddress: '',
    courierReference: 'PENDIENTE'
}


function CreateForm() {

    const [shpmtRegionOptions, setShpmtRegionOptions] = useState([]);
    const [shpmtProvinceOptions, setShpmtProvinceOptions] = useState([]);
    const [shpmtDistrictOptions, setShpmtDistrictOptions] = useState([]);

    const [formData, setFormData] = useState(intialValues);
    const [errors, setErrors] = useState({});
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: false });
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [isClientCodeFound, setIsClientCodeFound] = useState(false);
    const [isSeachWithError, setIsSeachWithError] = useState(false);
    const [isPreSale, setIsPreSale] = useState(false);

    const [saleAmount, setSaleAmount] = useState('');
    const [shpmtDocumentNumber, setShpmtDocumentNumber] = useState('');
    const [shpmtContactNumber, setShpmtContactNumber] = useState('');

    const [searchCodeStatus, setSearchCodeStatus] = useState({ success: null, message: ''});

    const navigate = useNavigate();

    useEffect(() => {
        loadRegions();
    }, []);

    useEffect(() => {
        console.log({errors, xq: formData.salePaymentDate})
    }, [errors]);

    const handleChange = (e, { name, value }) => {

        console.log({e,name,value})
        
        if (name === "shpmtDocumentType") {
            setShpmtDocumentNumber("");
        }
        
        if(name === "salesChannel"){
            loadToInitialState();
        }

        if(name === "shpmtRegion") {
            loadProvincesByRegion(value);
            setFormData({ ...formData, shpmtProvince: '', shpmtDistrict: '', shpmtRegion: value });
            setErrors({ ...errors, shpmtRegion: false });
            setShpmtProvinceOptions([]);
            setShpmtDistrictOptions([]);
            return;
        }
        
        if(name === "shpmtProvince") {
            loadDistrictsByProvince(value);
            setFormData({ ...formData, shpmtDistrict: '', shpmtProvince: value });
            setErrors({ ...errors, shpmtProvince: false });
            setShpmtDistrictOptions([]);
            return;
        }
        
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false });
    };

    const loadToInitialState = ()=>{
        setErrors({});
        setFormData(intialValues);
        setSearchCodeStatus({ success: null, message: ''});
        setSaleAmount('');
        setShpmtDocumentNumber('');
        setShpmtContactNumber('');
        setIsPreSale(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsFormLoading(true)

        setErrors({
            salePaymentDate: formData.salePaymentDate === '',
            saleBankName: formData.saleBankName === '',
            saleAmount: `${saleAmount}` === '',
            saleInvoiceCode: formData.saleInvoiceCode === '',
            shpmtDocumentType: formData.shpmtDocumentType === '',
            shpmtDocumentNumber: shpmtDocumentNumber === '',
            shpmtName: formData.shpmtName === '',
            shpmtPaternalSurname: formData.shpmtPaternalSurname === '',
            shpmtMaternalSurname: formData.shpmtMaternalSurname === '',
            shpmtRegion: formData.shpmtRegion === '',
            shpmtProvince: formData.shpmtProvince === '',
            shpmtDistrict: formData.shpmtDistrict === '',
            shpmtAddress: formData.shpmtAddress === '',
            shpmtReference: formData.shpmtReference === '',
            shpmtContactNumber: shpmtContactNumber === '',
            shpmtEmail: !isValidEmailFormat(formData.shpmtEmail),
            courierShippmentType: formData.courierShippmentType === '',
            courierAddress: formData.courierAddress === '',
            courierReference: formData.courierReference === ''
        })

        if (
            formData.salePaymentDate === '' ||
            formData.saleBankName === '' ||
            `${saleAmount}` === '' ||
            formData.saleInvoiceCode === '' ||
            formData.shpmtDocumentType === '' ||
            shpmtDocumentNumber === '' ||
            formData.shpmtName === '' ||
            formData.shpmtPaternalSurname === '' ||
            formData.shpmtMaternalSurname === '' ||
            formData.shpmtRegion === '' ||
            formData.shpmtProvince === '' ||
            formData.shpmtDistrict === '' ||
            formData.shpmtAddress === '' ||
            formData.shpmtReference === '' ||
            shpmtContactNumber === '' ||
            !isValidEmailFormat(formData.shpmtEmail) ||
            formData.courierShippmentType === '' ||
            formData.courierAddress === '' ||
            formData.courierReference === ''
        ) {
            setIsFormLoading(false);
            return;
        }

        const body = ShipmentDataBody({
            formData, saleAmount, shpmtDocumentNumber, shpmtContactNumber, isPreSale
        })

        try {
            const responseData = await createShipmentData(body);
            setSubmitStatus({ success: true, error: false });
        } catch (e) {
            setSubmitStatus({ success: false, error: true });
        }

        setIsFormLoading(false);
    };


    const webSeachEvent = async (e) => {
        if (formData.clientCode === '') {
            setSearchCodeStatus({ success: false, message: 'Debe ingresar un codigo para buscar' });
            return
        }

        try {
            const data = await getSaleWithCode(formData.clientCode);
            setSearchCodeStatus({ success: true, message: '' });
            setLoadedData(data);
        } catch (error) {
            if (error instanceof CustomError) {
                // Handle custom errors
                switch (error.statusCode) {
                    case 400:
                        setSearchCodeStatus({ success: false, message: 'Ha ocurrido un error' });
                        break;
                    case 401:
                        setSearchCodeStatus({ success: false, message: 'Ha ocurrido un error' });
                        break;
                    case 404:
                        setSearchCodeStatus({ success: false, message: 'No se ha encontrado el registro' });
                        break;
                    case 500:
                        setSearchCodeStatus({ success: false, message: 'Ha ocurrido un error' });
                        break;
                    default:
                        setSearchCodeStatus({ success: false, message: 'Ha ocurrido un error' });
                }
            } else {
                // Handle any non-custom errors
                setSearchCodeStatus({ success: false, message: 'Ha ocurrido un error' });
            }
        }

        // //Call this if code was not found
        // setIsSeachWithError(true);

        // Call this if code was found
        // setIsClientCodeFound(true);
    };


    const setLoadedData = (data) => {
        const department = data.region ? data.region : '';
        const province = data.province ? data.province : '';
        const district = data.district ? data.district : '';
        const address = data.address ? data.address : '';
        const reference = data.addressReference ? data.addressReference : '';
        const documentType = data.typeNumberId ? data.typeNumberId : '';
        const documentNumber = data.numberId ? data.numberId : '';
        const phoneNumber = data.phoneReference ? data.phoneReference : '';
        const email = data.email ? data.email : '';
        const name = data.firstname ? data.firstname : '';
        const lastName = data.lastname ? data.lastname : '';
        const surLastName = '-';
        const totalAmount = data.totalAmount ? data.totalAmount : '';

        setFormData({
            ...formData,
            shpmtRegion: department,
            shpmtProvince: province,
            shpmtDistrict: district,
            shpmtAddress: address,
            shpmtReference: reference,
            shpmtEmail: email,
            shpmtDocumentType: documentType,
            shpmtName: name,
            shpmtPaternalSurname: lastName,
            shpmtMaternalSurname: surLastName,
        });

        setSaleAmount(totalAmount);
        loadProvincesAndDistrictsFrom(department, province);
        setShpmtDocumentNumber(documentNumber);
        setShpmtContactNumber(phoneNumber);
    }

    const isPreSaleEvent = (e, { checked }) => {
        setIsPreSale(checked);
    }

    const isValidEmailFormat = (emailValue) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(emailValue);
    }

    const submitStatusEnded = () => {
        return submitStatus.success || submitStatus.error
    }

    const goToList = () => {
        navigate('/');
    };

    const goToForm = () => {
        navigate('/create');
    };

    const loadRegions = async () => {
        try {
            const responseData = await getRegions();
            let options = responseData.map((item) => {
                return { key: item.id, text: item.name, value: item.name }
            });
            setShpmtRegionOptions(options);
        } catch (e) {
            console.log(e)
        }
    }

    const loadProvincesByRegion = async (regionName) => {
        const selectedOption = shpmtRegionOptions.find(option => option.value === regionName);
        const selectedKey = selectedOption ? selectedOption.key : null;
        if (!selectedKey) { return }
        try {
            const responseData = await getProvincesFromRegion(selectedKey);
            let options = responseData.map((item) => {
                return { key: item.id, text: item.name, value: item.name }
            });
            setShpmtProvinceOptions(options);
        } catch (e) {
            console.log(e)
        }
    }

    const loadDistrictsByProvince = async (provinceName) => {
        const selectedOption = shpmtProvinceOptions.find(option => option.value === provinceName);
        const selectedKey = selectedOption ? selectedOption.key : null;
        if (!selectedKey) { return }
        try {
            const responseData = await getDistrictsFromProvince(selectedKey);
            let options = responseData.map((item) => {
                return { key: item.id, text: item.name, value: item.name }
            });
            setShpmtDistrictOptions(options);
        } catch (e) {
            console.log(e)
        }
    }

    const loadProvincesAndDistrictsFrom = async (regionName, provinceName) => {
        const selectedRegion = shpmtRegionOptions.find(option => option.value === regionName);
        const regionId = selectedRegion ? selectedRegion.key : null;
        if (!regionId) { return }

        try {
            const provinceRawData = await getProvincesFromRegion(regionId);
            let provincesFormatted = provinceRawData.map((item) => {
                return { key: item.id, text: item.name, value: item.name }
            });

            const selectedProvince = provincesFormatted.find(option => option.value === provinceName);
            const provinceId = selectedProvince ? selectedProvince.key : null;
            if (!provinceId) { return }

            const districtRawData = await getDistrictsFromProvince(provinceId);
            let districtsFormatted = districtRawData.map((item) => {
                return { key: item.id, text: item.name, value: item.name }
            });

            setShpmtProvinceOptions(provincesFormatted);
            setShpmtDistrictOptions(districtsFormatted);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Grid style={{ "justifyContent": "center", "marginTop": "20px", "marginBottom": "20px" }}>
            <Grid.Column style={{ maxWidth: 820 }}>
                {
                    submitStatus.success ?
                        null
                        :
                        <>
                            <Button
                                style={{"marginBottom":"20px"}}
                                color='black'
                                onClick={goToList}
                            >
                                Volver a la lista
                            </Button>
                        </>
                }
                {
                    submitStatusEnded() ? null :
                        <Form onSubmit={handleSubmit} loading={isFormLoading}>
                            <h1>Crear Envío</h1>
                            <FormGroup grouped>
                                <FormField
                                    name="salesChannel"
                                    control={Select}
                                    label='Canal de Venta'
                                    options={salesChannelOptions}
                                    value={formData.salesChannel}
                                    placeholder=''
                                    onChange={handleChange}
                                    error={errors.salesChannel ? {
                                        content: 'Campo necesario',
                                        pointing: 'below',
                                    } : null}
                                    disabled={false}
                                />
                                <FormGroup>
                                    <FormField
                                        name="clientCode"
                                        control={Input}
                                        label='Codigo de Cliente'
                                        placeholder='Ingresar'
                                        value={formData.clientCode}
                                        width={16}
                                        onChange={handleChange}
                                        error={errors.clientCode ? {
                                            content: 'Campo necesario',
                                            pointing: 'below',
                                        } : null}
                                        disabled={searchCodeStatus.success}
                                    />
                                    {
                                        formData.salesChannel == "Web" ?
                                            searchCodeStatus.success ?
                                                null
                                                :
                                                <div style={{ "position": "relative", "display": "block" }}>
                                                    <Button
                                                        type='button'
                                                        onClick={webSeachEvent}
                                                        disabled={searchCodeStatus.success}
                                                        style={{ "position": "relative", "top": "30px" }}
                                                    >
                                                        Buscar
                                                    </Button>
                                                </div>
                                            :
                                            null
                                    }
                                </FormGroup>
                            </FormGroup>


                            {
                                (formData.salesChannel == SALES_CHANNEL_WEB && searchCodeStatus.success) || (formData.salesChannel == SALES_CHANNEL_NETWORK) ?
                                    <>
                                        <FormGroup inline>
                                            <FormField>
                                                <h1>Datos de Venta</h1>
                                            </FormField>
                                            <FormField>
                                                <Checkbox
                                                    checked={isPreSale}
                                                    onChange={isPreSaleEvent}
                                                    label='Preventa' className='pre-sale-checkbox' />
                                            </FormField>
                                        </FormGroup>

                                        <FormGroup>
                                            <Form.Field
                                                control={Input}
                                                label='Fecha y Hora deposito:'
                                                type='datetime-local'
                                                width={5}
                                                value={formData.salePaymentDate}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    handleChange({}, { name: "salePaymentDate", value: value })
                                                }}
                                                error={errors.salePaymentDate ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                onKeyDown={(e) => e.preventDefault()}
                                            />
                                            <FormField
                                                name="saleBankName"
                                                value={formData.saleBankName}
                                                control={Select}
                                                label='Banco'
                                                options={bankOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.saleBankName ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                width={11}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormField
                                                name="saleAmount"
                                                error={errors.saleAmount ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                onChange={(e, { name, value }) => {
                                                    const regex = /^[0-9]*\.?[0-9]*$/;
                                                    if (value === '' || regex.test(value)) {
                                                        setSaleAmount(value);
                                                        setErrors({ ...errors, ["saleAmount"]: false });
                                                    }
                                                }}
                                                value={saleAmount}
                                                control={Input}
                                                label='Monto'
                                                placeholder='Ingresar Monto'
                                                width={8}
                                                disabled={searchCodeStatus.success}
                                            />
                                            <FormField
                                                name="saleInvoiceCode"
                                                onChange={handleChange}
                                                error={errors.saleInvoiceCode ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Codigo de Boleta'
                                                placeholder='3215-1321'
                                                width={8}
                                            />
                                        </FormGroup>

                                        <FormGroup inline>
                                            <h1>Datos de Envio</h1>
                                        </FormGroup>

                                        <FormGroup>
                                            <FormField
                                                name="shpmtDocumentType"
                                                error={errors.shpmtDocumentType ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Select}
                                                value={formData.shpmtDocumentType}
                                                label='Tipo de Documento:'
                                                options={shpmtDocumentTypeOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                disabled={false}
                                                width={8}
                                            />
                                            <FormField
                                                onChange={(e, { name, value }) => {
                                                    const dniRegex = /^[0-9]{0,8}$/;
                                                    const ceRegex = /^[0-9]{0,12}$/;
                                                    const regex = formData.shpmtDocumentType === DOCUMENT_TYPE_DNI ? dniRegex : ceRegex;

                                                    if (value === '' || regex.test(value)) {
                                                        setShpmtDocumentNumber(value);
                                                        setErrors({ ...errors, ["shpmtDocumentNumber"]: false });
                                                    }
                                                }}
                                                name="shpmtDocumentNumber"
                                                error={errors.shpmtDocumentNumber ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Documento'
                                                placeholder='N de Documento'
                                                width={8}
                                                value={shpmtDocumentNumber}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormField
                                                onChange={handleChange}
                                                value={formData.shpmtName}
                                                name="shpmtName"
                                                error={errors.shpmtName ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Nombre'
                                                placeholder='Jose Miguel'
                                                width={6}
                                            />
                                            <FormField
                                                onChange={handleChange}
                                                value={formData.shpmtPaternalSurname}
                                                name="shpmtPaternalSurname"
                                                error={errors.shpmtPaternalSurname ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Apellido Paterno'
                                                placeholder='Sanchez'
                                                width={5}
                                            />
                                            <FormField
                                                onChange={handleChange}
                                                value={formData.shpmtMaternalSurname}
                                                name="shpmtMaternalSurname"
                                                error={errors.shpmtMaternalSurname ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Apellido Materno:'
                                                placeholder='Navarro'
                                                width={5}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormField
                                                name="shpmtRegion"
                                                value={formData.shpmtRegion}
                                                control={Select}
                                                label='Departamento:'
                                                options={shpmtRegionOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.shpmtRegion ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                disabled={false}
                                                width={5}
                                            />
                                            <FormField
                                                name="shpmtProvince"
                                                value={formData.shpmtProvince}
                                                control={Select}
                                                label='Provincia:'
                                                options={shpmtProvinceOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.shpmtProvince ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                disabled={false}
                                                width={5}
                                            />
                                            <FormField
                                                name="shpmtDistrict"
                                                value={formData.shpmtDistrict}
                                                control={Select}
                                                label='Distrito:'
                                                options={shpmtDistrictOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.shpmtDistrict ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                disabled={false}
                                                width={6}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormField
                                                onChange={handleChange}
                                                value={formData.shpmtAddress}
                                                name="shpmtAddress"
                                                error={errors.shpmtAddress ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Direccion:'
                                                placeholder='Ingresar direccion'
                                                width={16}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormField
                                                onChange={handleChange}
                                                name="shpmtReference"
                                                value={formData.shpmtReference}
                                                error={errors.shpmtReference ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Referencia:'
                                                placeholder='Escriba una referencia sobre la direccion'
                                                width={16}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <FormField
                                                onChange={(e, { name, value }) => {
                                                    const regex = /^[0-9]{0,9}$/;
                                                    if (value === '' || regex.test(value)) {
                                                        setShpmtContactNumber(value);
                                                        setErrors({ ...errors, ["shpmtContactNumber"]: false });
                                                    }
                                                }}
                                                name="shpmtContactNumber"
                                                error={errors.shpmtContactNumber ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Celular de Contacto:'
                                                placeholder='987654321'
                                                width={8}
                                                value={shpmtContactNumber}
                                            />
                                            <FormField
                                                name="shpmtEmail"
                                                error={errors.shpmtEmail ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Correo:'
                                                placeholder='ejemplo@gmail.com'
                                                width={8}
                                                onChange={handleChange}
                                                value={formData.shpmtEmail}
                                            />
                                        </FormGroup>

                                        <FormGroup inline>
                                            <h1>Datos de Courier</h1>
                                        </FormGroup>

                                        <FormGroup >
                                            <FormField
                                                name="courierShippmentType"
                                                control={Select}
                                                label='Tipos de Envio:'
                                                options={courierShippmentTypeOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.courierShippmentType ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                disabled={false}
                                                width={16}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <FormField
                                                name="courierAddress"
                                                value={formData.courierAddress}
                                                control={Select}
                                                label='Tipo de entrega:'
                                                options={kindDeliveryOptions}
                                                placeholder='Seleccionar'
                                                onChange={handleChange}
                                                error={errors.courierAddress ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                width={16}
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <FormField
                                                onChange={handleChange}
                                                name="courierReference"
                                                error={errors.courierReference ? {
                                                    content: 'Campo necesario',
                                                    pointing: 'below',
                                                } : null}
                                                control={Input}
                                                label='Código de seguimiento:'
                                                placeholder='Ingresar'
                                                width={16}
                                            />
                                        </FormGroup> */}
                                        <Button type='submit'>Crear</Button>
                                    </>
                                    :
                                    searchCodeStatus.success == false ?                                        
                                        <Message negative>
                                            <MessageHeader>{`${searchCodeStatus.message}`}</MessageHeader>
                                        </Message>
                                        :
                                        null
                            }
                        </Form>
                }
                {submitStatus.success && (
                    <Message positive>
                        <Message.Header>Operacion Exitosa</Message.Header>
                        <p>Los datos fueron registrados exitosamente</p>
                        <Button
                            color='black'
                            onClick={goToList}
                        >
                            Volver a la lista
                        </Button>
                        {/* <Button
                            content="Agregar otro"
                            onClick={goToForm}
                            positive
                        /> */}
                    </Message>
                )}
                {submitStatus.error && !errors.gender && (
                    <Message negative>
                        <Message.Header>Envio Fallido</Message.Header>
                        <p>Ocurrio un error al registrar</p>
                        <Button
                            color='black'
                            onClick={goToList}
                        >
                            Volver a la lista
                        </Button>
                    </Message>
                )}
            </Grid.Column>
        </Grid>
    );
}

export default CreateForm;
