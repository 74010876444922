import { DINSIDES_CHECKBOX_ID, DOCUMENT_FIELD_ID, FROM_DATE_FIELD_ID, OLVA_CHECKBOX_ID, TO_DATE_FIELD_ID, TRACKING_CODE_FIELD_ID } from "../Constants";
import { formatDateToLocale } from "../Util";


export class TagItem {
    constructor(id, value) {
        this.id = id;
        this.value = value;
    }

    getValueFormatted = () => {
        switch (this.id) {
            case OLVA_CHECKBOX_ID:
                return `Tipo Envio: ${this.value}`;
            case DINSIDES_CHECKBOX_ID:
                return `Tipo Envio: ${this.value}`;
            case DOCUMENT_FIELD_ID:
                return `Documento: ${this.value}`;
            case FROM_DATE_FIELD_ID:                
                return `Fecha desde: ${formatDateToLocale(new Date(this.value))}`;
            case TO_DATE_FIELD_ID:
                return `Fecha hasta: ${formatDateToLocale(new Date(this.value))}`;
            case TRACKING_CODE_FIELD_ID:
                return `Cod seguimiento: ${this.value}`;
            default:
                return ""
                break;
        }
    }
}