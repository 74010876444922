export const formatDateToLocale = (date) => {
    let stringToDate = new Date(date);
    if (stringToDate instanceof Date && !isNaN(stringToDate)) {
        return stringToDate.toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    }
    return "";
}