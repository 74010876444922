import * as XLSX from 'xlsx';

// Función para exportar los datos
export const dinsidesExportToXSL = (data) => {
    // Definir algunos datos usando la clase DataModel
 

    // Convertir los objetos a un formato de array de objetos plano
    const formattedData = data.map((item) => ({
        "TIPO DE VENTA (SELECCIONE SOLO DEL LISTADO)": "SOLO-ENTREGA-NO-COBRAR",
        "NOMBRE DEL DESTINATARIO": `${item.firstName} ${item.lastName} ${item.secondLastName}`,
        "TELEFONO DESTINATARIO 9 DIGITOS": item.contactNumber,
        "DISTRITO (SELECCIONE SOLO DEL LISTADO)": item.locationDistrict,
        "DIRECCION DE ENTREGA": item.locationAddress,
        "COORDENADAS DE LA DIRECCIÓN": "",
        "FECHA DE ENTREGA (DIA/MES/AÑO)": "",
        "DETALLE DEL PRODUCTO": "Ropa",
        "MONTO A COBRAR (decimales se separan con punto . )": "0",
        "FORMA DE PAGO": "No Cobrar",
        "OBSERVACION": "No Cobrar"
    }));

    // Crear una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

    // Exportar el archivo Excel
    XLSX.writeFile(workbook, 'Envios_Dinsides.xlsx');
};