import * as XLSX from 'xlsx';

// Función para exportar los datos
export const generalExportToXSL = (data) => {
    // Definir algunos datos usando la clase DataModel


    const formatDate = (dateString) => {
        const date = new Date(dateString);
      
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses empiezan en 0
        const day = String(date.getDate()).padStart(2, '0');
      
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }


    // Convertir los objetos a un formato de array de objetos plano
    const formattedData = data.map((item) => ({
        "Canal de Venta": item.saleChannel,
        "Codigo de Cliente": item.clientCode,
        "Es Preventa?": item.isPreSale === true ? "SI" : "NO",
        "Fecha/Hora Deposito": formatDate(item.depositDate),
        "Banco": item.bank,
        "Monto": item.depositAmount,
        "Codigo de Boleta": item.boletaCode,
        "Tipo de Documento": item.documentType,
        "Documento": item.documentNumber,
        "Nombre": item.firstName,
        "Apellido Paterno": item.lastName,
        "Apellido Materno": item.secondLastName,
        "Departamento": item.locationDepartment,
        "Provincia": item.locationProvince,
        "Distrito": item.locationDistrict,
        "Direccion": item.locationAddress,
        "Referencia": item.locationReference,
        "N de Contacto": item.contactNumber,
        "Email": item.email,
        "Tipo de Envio": item.shipmentType,
        "Tipo de Entrega": item.deliverType,
        "Código de seguimiento": item.remito
    }));

    // Crear una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');

    // Exportar el archivo Excel
    XLSX.writeFile(workbook, 'Exportar_Todos.xlsx');
};