
import axios from 'axios';
import Routes from './Routes';

export class CustomError extends Error {
  constructor(message, statusCode) {
    super(message);
    this.statusCode = statusCode;
  }
}

export let getShipmentData = async (pageSize, page) => {
  try {
    const response = await axios.get(Routes.shipmentDataList(pageSize, page))
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export let getShipmentDataCount = async () => {
  try {
    const response = await axios.get(Routes.shipmentDataCount);
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export const createShipmentData = async (data) => {
  try {
    const response = await axios.post(Routes.shipmentDataCreate, data);
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export let getSaleWithCode = async (saleCode) => {
  try {
    const response = await axios.get(Routes.getSaleWithCode(saleCode));
    return response.data;
  } catch (error) {
    let customError;

    if (error.response) {
      // Error response from the server
      const statusCode = error.response.status;
      customError = new CustomError(`Request failed with status code ${statusCode}`, statusCode);
    } else if (error.request) {
      // Request made but no response
      customError = new CustomError("No response received from the server", 0);
    } else {
      // Other errors
      customError = new CustomError(`An unexpected error occurred: ${error.message}`, 0);
    }

    // Throw the custom error
    throw customError;
  }
};

export let getRegions = async () => {
  try {
    const response = await axios.get(Routes.regions);
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export let getProvincesFromRegion = async (regionId) => {
  try {
    const response = await axios.get(Routes.getProvinceFromRegion(regionId));
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export let getDistrictsFromProvince = async (provinceId) => {
  try {
    const response = await axios.get(Routes.getDistrictsFromProvince(provinceId));
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};

export const updateRemitoWithId = async (shipmentDataId, data) => {
  try {
    const response = await axios.patch(Routes.updateRemito(shipmentDataId), data);
    return response.data;
  } catch (error) {
    throw JSON.parse(JSON.stringify(error));
  }
};