export const OLVA_CHECKBOX_ID = "OLVA_CHECKBOX_ID"
export const DINSIDES_CHECKBOX_ID = "DINSIDES_CHECKBOX_ID"
export const DOCUMENT_FIELD_ID = "DOCUMENT_FIELD_ID"
export const FROM_DATE_FIELD_ID = "FROM_DATE_FIELD_ID"
export const TO_DATE_FIELD_ID = "TO_DATE_FIELD_ID"
export const TRACKING_CODE_FIELD_ID = "TRACKING_CODE_FIELD_ID"

//MODAL
export const TC_CODE_ASSIGN = "TC_CODE_ASSIGN";
export const TC_PENDING_ASSIGN = "TC_PENDING_ASSIGN";
export const TC_ANULL_ASSIGN = "TC_ANULL_ASSIGN";
export const PENDING_VALUE = "PENDIENTE";
export const ANULL_VALUE = "ANULADO";
export const CODE_ASSIGN_VALUE = "CODIGO ASIGNADO";