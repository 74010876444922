const url = `${process.env.REACT_APP_API}`;

const Routes = {
  shipmentDataCreate: `${url}/api/ShipmentData`,
  shipmentDataList: (pageSize, page) => {
    return `${url}/api/ShipmentData?filter={"limit": ${pageSize},"skip": ${pageSize * (page - 1)}}`
  },
  shipmentDataCount: `${url}/api/ShipmentData/count`,
  getSaleWithCode: (code) => {
    return `${url}/api/Sales/findOne?filter={"where":{"purchaseCode":"${code}"}}`
  },  
  regions: `${url}/api/Regions`,
  getProvinceFromRegion: (regionId)=>{
    return `${url}/api/Regions/${regionId}/provinces`
  },
  getDistrictsFromProvince: (provinceId)=>{
    return `${url}/api/Provinces/${provinceId}/districts`
  },
  updateRemito: (shipmentDataId) => {
    return `${url}/api/ShipmentData/${shipmentDataId}`
  },  
}

export default Routes;