import React, { useState, useEffect } from 'react';
import { FormField, FormGroup, Form, Input, Grid, Label, Icon, Checkbox, Button, Message, Select } from 'semantic-ui-react'
import './ItemList.css'
import 'react-datepicker/dist/react-datepicker.css';
import ItemTable from './ItemTable';
import { fakerES as faker } from '@faker-js/faker';
import { getShipmentData, getShipmentDataCount } from '../Networking/v1/endpoints';
import { TagItem } from './TagItem';
import { ANULL_VALUE, CODE_ASSIGN_VALUE, DINSIDES_CHECKBOX_ID, DOCUMENT_FIELD_ID, FROM_DATE_FIELD_ID, OLVA_CHECKBOX_ID, PENDING_VALUE, TC_CODE_ASSIGN, TO_DATE_FIELD_ID, TRACKING_CODE_FIELD_ID } from '../Constants';
import { formatDateToLocale } from '../Util';

import { useNavigate } from 'react-router-dom';

const paymentTypes = ["Yape", "Plin", "BCP", "BBVA", "INTERBANK", "MERCADOPAGO"];
const shippingType = ["OLVA", "DINSIDES"];
const getRandomFromArray = (array) => {
    return array[Math.floor(Math.random() * array.length)];
};

const trackingCodeOptions = [
    {
        key: PENDING_VALUE,
        text: PENDING_VALUE,
        value: PENDING_VALUE
    },
    {
        key: ANULL_VALUE,
        text: ANULL_VALUE,
        value: ANULL_VALUE
    },
    {
        key: TC_CODE_ASSIGN,
        text: CODE_ASSIGN_VALUE,
        value: CODE_ASSIGN_VALUE
    }
];

class ShipmentData {
    constructor(dataItem) {
        this.depositDate = dataItem.depositDate;
        this.bank = dataItem.bank;
        this.depositAmount = dataItem.depositAmount;
        this.isPreSale = dataItem.isPreSale;
        this.clientCode = dataItem.clientCode;
        this.saleChannel = dataItem.saleChannel;
        this.remito = dataItem.remito;
        this.boletaCode = dataItem.boletaCode;
        this.shipmentType = dataItem.shipmentType;
        this.deliverType = dataItem.deliverType;
        this.locationDepartment = dataItem.locationDepartment;
        this.locationProvince = dataItem.locationProvince;
        this.locationDistrict = dataItem.locationDistrict;
        this.locationAddress = dataItem.locationAddress;
        this.locationReference = dataItem.locationReference;
        this.documentType = dataItem.documentType;
        this.documentNumber = dataItem.documentNumber;
        this.contactNumber = dataItem.contactNumber;
        this.email = dataItem.email;
        this.firstName = dataItem.firstName;
        this.lastName = dataItem.lastName;
        this.secondLastName = dataItem.secondLastName;
        this.saleCoordinates = dataItem.saleCoordinates;
        this.productDetail = dataItem.productDetail;
        this.id = dataItem.id;
        this.registrationDate = dataItem.registrationDate;
    }

    getFormatToDisplay() {
        let dataToDisplay = {
            saleChannel: `${this.saleChannel}`,
            clientCode: this.clientCode,
            isPreSale: this.isPreSale ? "SI" : "NO",
            depositDate: formatDateToLocale(this.depositDate),
            bank: `${this.bank}`,
            amount: `${this.depositAmount}`,
            boletaCode: `${this.boletaCode}`,
            documentType: `${this.documentType}`,
            documentNumber: `${this.documentNumber}`,
            firstName: `${this.firstName}`,
            lastName: `${this.lastName}`,
            secondLastName: `${this.secondLastName}`,
            locationDepartment: `${this.locationDepartment}`,
            locationProvince: `${this.locationProvince}`,
            locationDistrict: `${this.locationDistrict}`,
            locationAddress: `${this.locationAddress}`,
            locationReference: `${this.locationReference}`,
            contactNumber: `${this.contactNumber}`,
            email: `${this.email}`,
            shipmentType: `${this.shipmentType}`,
            deliverType: `${this.deliverType}`,
            remito: `${this.remito}`,
            registrationDate: formatDateToLocale(this.registrationDate)
        };
        return dataToDisplay;
    }
}

function ItemList() {
    const navigate = useNavigate();
    const [documentNumber, setDocumentNumber] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [array, setArray] = useState([]);
    const [isOlvaChecked, setIsOlvaChecked] = useState(false);
    const [isDinsidesChecked, setIsDinsidesChecked] = useState(false);
    const [shipmentData, setShipmentData] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(7);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isDataEmpty, setIsDataEmpty] = useState(true);
    const [trackingCode, setTrackingCode] = useState('');

    useEffect(() => {
        loadTotalNumberItems();
    }, []);

    useEffect(() => {
        loadShipmentData(itemsPerPage, currentPage);
    }, [currentPage]);

    const loadCurrentPage = () => {
        loadShipmentData(itemsPerPage, currentPage);
        setDocumentNumber('');
        setFromDate('');
        setToDate('');
        setIsOlvaChecked(false);
        setIsDinsidesChecked(false);
        array.map((item) => {
            removeTagItem(item)
            return true;
        })
    }

    const loadTotalNumberItems = async () => {
        try {
            const responseData = await getShipmentDataCount();
            const totalItems = responseData.count;
            if (totalItems > 0) {
                const pages = parseInt(totalItems / itemsPerPage);
                const residualPages = parseInt(totalItems % itemsPerPage) > 0 ? 1 : 0;
                setTotalPages(pages + residualPages);
                loadShipmentData(itemsPerPage, currentPage);
                setIsDataEmpty(false);
            } else {
                //MOSTRAR UN MENSAJE DE QUE NO HAY DATOS
                setIsDataEmpty(true);
            }
        } catch (e) {
            console.log(e);
        }
    }


    const loadShipmentData = async (pageSize, page) => {
        try {
            const responseData = await getShipmentData(pageSize, page);
            const shipmentDataFormatted = responseData.map((dataItem) => {
                return new ShipmentData(dataItem);
            })
            setShipmentData(shipmentDataFormatted);
        } catch (e) {
            console.log(e);
        }
    }

    const addTagItem = (newItem) => {
        setArray((prevArray) => {
            const filteredArray = prevArray.filter(item => item.id !== newItem.id);
            return [...filteredArray, newItem];
        });
    };

    const removeTagItem = ({ id }) => {
        setArray((prevArray) => {

            if (id == DOCUMENT_FIELD_ID) {
                const documentNumberElement = document.getElementById(DOCUMENT_FIELD_ID);
                documentNumberElement.disabled = false;
            } else if (id == OLVA_CHECKBOX_ID) {
                const dinsidesCheckbox = document.getElementById(DINSIDES_CHECKBOX_ID);
                dinsidesCheckbox.disabled = false;
                setIsOlvaChecked(false);
            } else if (id == DINSIDES_CHECKBOX_ID) {
                const olvaCheckbox = document.getElementById(OLVA_CHECKBOX_ID);
                olvaCheckbox.disabled = false;
                setIsDinsidesChecked(false);
            } else if (id == FROM_DATE_FIELD_ID) {
                setFromDate('');
            } else if (id == TO_DATE_FIELD_ID) {
                setToDate('');
            } else if (id == TRACKING_CODE_FIELD_ID) {
                setTrackingCode('');
            }

            return prevArray.filter(item => item.id !== id);
        });
    };

    const handleKeyPress = (event, test) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (documentNumber.trim() !== '') {
                addTagItem(new TagItem(DOCUMENT_FIELD_ID, documentNumber));  // Add to array
                setDocumentNumber('');  // Clear input field
                event.target.disabled = true;
            }
        }
    };

    const handleCheckboxChange = (event, { checked, name, value, id }) => {

        if (id == OLVA_CHECKBOX_ID) {
            if (checked) {
                const dinsidesCheckbox = document.getElementById(DINSIDES_CHECKBOX_ID);
                dinsidesCheckbox.disabled = true;
                // ADD OLVA TO ARRAY
                addTagItem(new TagItem(OLVA_CHECKBOX_ID, "OLVA"));
                removeTagItem(new TagItem(DINSIDES_CHECKBOX_ID, "DINSIDES"));
                setIsOlvaChecked(true);
                setIsDinsidesChecked(false);
                console.log("bloq1")
            } else {
                const dinsidesCheckbox = document.getElementById(DINSIDES_CHECKBOX_ID);
                dinsidesCheckbox.disabled = false;
                // REMOVE FROM ARRAY
                removeTagItem(new TagItem(OLVA_CHECKBOX_ID, "OLVA"));
                removeTagItem(new TagItem(DINSIDES_CHECKBOX_ID, "DINSIDES"));
                setIsOlvaChecked(false);
                setIsDinsidesChecked(false);
                console.log("bloq2")
            }
        } else if (id == DINSIDES_CHECKBOX_ID) {
            if (checked) {
                const olvaCheckbox = document.getElementById(OLVA_CHECKBOX_ID);
                olvaCheckbox.disabled = true;
                addTagItem(new TagItem(DINSIDES_CHECKBOX_ID, "DINSIDES"));
                removeTagItem(new TagItem(OLVA_CHECKBOX_ID, "OLVA"));
                setIsOlvaChecked(false);
                setIsDinsidesChecked(true);
                console.log("bloq3")
            } else {
                const olvaCheckbox = document.getElementById(OLVA_CHECKBOX_ID);
                olvaCheckbox.disabled = false;
                removeTagItem(new TagItem(OLVA_CHECKBOX_ID, "OLVA"));
                removeTagItem(new TagItem(DINSIDES_CHECKBOX_ID, "DINSIDES"));
                setIsOlvaChecked(false);
                setIsDinsidesChecked(false);
                console.log("bloq4")
            }
        }
    };

    const onChangeOnlyNumbers = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {  // Check if value contains only digits
            setDocumentNumber(value);
        }
    }

    const onChangeDate = (e) => {
        const value = e.target.value;
        const id = e.target.id;

        if (id == FROM_DATE_FIELD_ID) {
            addTagItem(new TagItem(FROM_DATE_FIELD_ID, value));
            setFromDate(value);
        } else if (id == TO_DATE_FIELD_ID) {
            addTagItem(new TagItem(TO_DATE_FIELD_ID, value));
            setToDate(value);
        }
    }

    const handleSelectChange = (e, { value, id }) => {
        if (id == TRACKING_CODE_FIELD_ID) {
            addTagItem(new TagItem(id, value));
            setTrackingCode(value);
        }
      };

    const goToForm = () => {
        navigate('/create');
    };

    return (
        <Grid style={{ "justifyContent": "center", "margin": "50px" }}>
            <Grid.Column fluid>
                {
                    isDataEmpty ?
                        <Message positive>
                            <Message.Header>No se encontraron registros</Message.Header>
                            <p>No se encontraron datos para visualizar, contacte con el administrador</p>
                            <Button
                                color='black'
                                onClick={() => {
                                    loadTotalNumberItems();
                                }}
                            >
                                Volver a cargar
                            </Button>
                            <Button
                                content="Agregar Nuevo"
                                onClick={goToForm}
                                positive
                            />
                        </Message>
                        :
                        <>
                            <h3>Filtros Disponibles: </h3>
                            <Form>
                                <FormGroup>
                                    <Form.Field
                                        id={DOCUMENT_FIELD_ID}
                                        control={Input}
                                        label='Documento'
                                        placeholder=''
                                        width={4}
                                        value={documentNumber}
                                        onChange={onChangeOnlyNumbers}
                                        onKeyPress={handleKeyPress}
                                    />
                                    <Form.Field
                                        id={FROM_DATE_FIELD_ID}
                                        control={Input}
                                        label='Fecha desde:'
                                        type='datetime-local'
                                        width={4}
                                        value={fromDate}
                                        onChange={onChangeDate}
                                        onKeyPress={handleKeyPress}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                    <Form.Field
                                        id={TO_DATE_FIELD_ID}
                                        control={Input}
                                        label='Fecha hasta:'
                                        type='datetime-local'
                                        width={4}
                                        value={toDate}
                                        onChange={onChangeDate}
                                        onKeyPress={handleKeyPress}
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                    <Form.Field
                                        id={TRACKING_CODE_FIELD_ID}
                                        control={Select}
                                        value={trackingCode}
                                        label='Codigo de Seguimiento:'
                                        options={trackingCodeOptions}
                                        placeholder='Seleccionar'
                                        onChange={handleSelectChange}
                                        disabled={false}
                                        width={8}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormField
                                        id='form-input-control-last-name'
                                        control={Input}
                                        label='Tipo de Envio'
                                        width={4}
                                    >
                                        <Checkbox id={OLVA_CHECKBOX_ID} checked={isOlvaChecked} label={{ children: 'Olva' }} onChange={handleCheckboxChange} />
                                    </FormField>
                                    <FormField
                                        id='form-input-control-last-name'
                                        control={Input}
                                        label='Tipo de Envio'
                                        width={4}
                                    >
                                        <Checkbox id={DINSIDES_CHECKBOX_ID} checked={isDinsidesChecked} label={{ children: 'Dinsides' }} onChange={handleCheckboxChange} />
                                    </FormField>
                                </FormGroup>
                                <FormGroup inline>
                                    {
                                        array.length > 0 ?
                                            <h3>Filtros Seleccionados: </h3>
                                            :
                                            <></>
                                    }
                                </FormGroup>
                                <FormGroup inline>
                                    {
                                        array.map((item) => {
                                            return (
                                                <Label as='a'>
                                                    {item.getValueFormatted()}
                                                    <Icon name='delete' onClick={() => {
                                                        removeTagItem(item)
                                                    }} />
                                                </Label>
                                            );
                                        })
                                    }
                                </FormGroup>
                                <Form.Group inline className='right-aligned'>
                                    <Button primary onClick={goToForm}>Agregar Nuevo</Button>
                                </Form.Group>
                            </Form>
                            <ItemTable loadCurrentPage={loadCurrentPage} salesData={shipmentData} filters={array} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}></ItemTable>
                        </>
                }
            </Grid.Column>
        </Grid>
    );
}

export default ItemList;
