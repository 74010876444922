import logo from './logo.svg';
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import CreateForm from './Form/CreateForm';
import ItemList from './List/ItemList';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ItemList />} />
        <Route path="/create" element={<CreateForm />} />
      </Routes>
    </Router>
  );
}

export default App;
