import React, { useState, useEffect } from 'react';
import {
    ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Button,
    Segment,
    Modal,
    FormGroup,
    Label,
    Input,
    Checkbox,
    FormInput
} from 'semantic-ui-react'
import { updateRemitoWithId } from '../Networking/v1/endpoints';
import {
    TC_CODE_ASSIGN,
    TC_PENDING_ASSIGN,
    TC_ANULL_ASSIGN,
    PENDING_VALUE,
    ANULL_VALUE
} from '../Constants';

function TrackingCodeEditModal({ shipmentData, loadCurrentPage }) {
    const [firstOpen, setFirstOpen] = useState(false)
    const [secondOpen, setSecondOpen] = useState(false)
    const [selection, setSelection] = useState(TC_CODE_ASSIGN);
    const [fieldValue, setFieldValue] = useState('');

    useEffect(() => {

        if (selection === TC_CODE_ASSIGN) {
            setFieldValue('');
        }
        if (selection === TC_PENDING_ASSIGN) {
            setFieldValue(PENDING_VALUE);
        }
        if (selection === TC_ANULL_ASSIGN) {
            setFieldValue(ANULL_VALUE);
        }
    }, [selection]);

    const checkOnChange = (e, { checked, name }) => {
        if (name === TC_CODE_ASSIGN) {
            setSelection(TC_CODE_ASSIGN)
        }
        if (name === TC_PENDING_ASSIGN) {
            setSelection(TC_PENDING_ASSIGN)
        }
        if (name === TC_ANULL_ASSIGN) {
            setSelection(TC_ANULL_ASSIGN)
        }
    }

    const handleChange = (e, { name, value }) => {
        setFieldValue(value);
    }

    const updateRemito = async (shipmentData) => {
        if (!shipmentData || !shipmentData.id) {
            return
        }
        const shipmentId = shipmentData.id;
        const data = {
            remito: fieldValue
        };
        try {
            const responseData = await updateRemitoWithId(shipmentId, data);
            setSecondOpen(true);
            loadCurrentPage();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Label color="red" onClick={() => setFirstOpen(true)}> Editar </Label>
            <Modal
                closeIcon
                onClose={() => setFirstOpen(false)}
                onOpen={() => setFirstOpen(true)}
                open={firstOpen}
            >
                <ModalHeader>Editar Codigo de Seguimiento</ModalHeader>
                <ModalContent >
                    <ModalDescription>
                        <p>Seleccione el nuevo valor</p>
                        <FormGroup>
                            <Checkbox
                                radio
                                label='Asignar un codigo'
                                checked={selection === TC_CODE_ASSIGN}
                                onChange={checkOnChange}
                                name={TC_CODE_ASSIGN}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                radio
                                label='Marcar como PENDIENTE'
                                checked={selection === TC_PENDING_ASSIGN}
                                onChange={checkOnChange}
                                name={TC_PENDING_ASSIGN}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                radio
                                label='Marcar como ANULADO'
                                checked={selection === TC_ANULL_ASSIGN}
                                onChange={checkOnChange}
                                name={TC_ANULL_ASSIGN}
                            />
                        </FormGroup>
                        <Segment inverted>
                            <FormGroup>
                                <FormInput
                                    fluid
                                    label='Código de seguimiento:'
                                    placeholder=''
                                    onChange={handleChange}
                                    value={fieldValue}
                                    disabled={selection === TC_PENDING_ASSIGN || selection === TC_ANULL_ASSIGN}
                                />
                            </FormGroup>
                        </Segment>
                    </ModalDescription>
                </ModalContent>

                <ModalActions>
                    <Button
                        color='black'
                        onClick={() => setFirstOpen(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        content="Guardar"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => {
                            updateRemito(shipmentData);
                        }}
                        positive
                        disabled={fieldValue === ''}
                    />
                </ModalActions>

                <Modal
                    onClose={() => setSecondOpen(false)}
                    open={secondOpen}
                    size='small'
                >
                    <ModalHeader>Valor Guardado</ModalHeader>
                    <ModalContent>
                        <p>El nuevo valor es: {fieldValue}</p>
                    </ModalContent>
                    <ModalActions>
                        <Button
                            positive
                            icon='check'
                            content='Ok'
                            onClick={() => {
                                setFirstOpen(false)
                                setSecondOpen(false)
                            }}
                        />
                    </ModalActions>
                </Modal>
            </Modal>
        </>
    )
}

export default TrackingCodeEditModal