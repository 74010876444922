import jsPDF from 'jspdf';
import { imageLogo1 } from './Image1';
import { imageLogo2 } from './Image2';

export const generateVoucherPDF = (data) => {

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [104, 200]  // 203 ppp (4.09 pulgadas / 104 mm de ancho): 300 ppp (4.27 pulgadas / 108 mm de ancho):
  });

  doc.setFontSize(10);

  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const name = `${row.firstName} ${row.lastName} ${row.secondLastName}`;
    const document = `${row.documentNumber}`;
    const phone = `${row.contactNumber}`;
    const city = `${row.locationDepartment} - ${row.locationProvince} - ${row.locationDistrict}`;
    const clientCode = `${row.clientCode}`;

    // doc.addImage(imageLogo1, 'JPEG', 0, 0, 40, 20); // [x, y, width, height]
    // doc.addImage(imageLogo2, 'JPEG', 50, 0, 40, 20); // [x, y, width, height]

    doc.text('REMITENTE:', 10, 20);
    doc.text('FERNANDO MAGNO NUÑEZ CORDERO', 35, 20);
    doc.text('RUC:', 110, 20);
    doc.text('10702349490', 120, 20);
    doc.text('CELULAR:', 150, 20);
    doc.text('931259687', 170, 20);
    doc.text('DIRECCION:', 10, 30);
    doc.text('JR. AGUSTÍN GAMARRA #1160, SEXTO PISO TIENDA 636 GALERÍA "SAN PEDRO",', 35, 30);
    doc.text('LA VICTORIA LIMA', 32, 35);

    doc.text('PARA:', 10, 50);
    doc.text('NOMBRE:', 10, 55);
    doc.text(name, 30, 55);
    doc.text('DNI:', 10, 60);
    doc.text(document, 20, 60);
    doc.text('TELEFONO:', 40, 60);
    doc.text(phone, 65, 60);
    doc.text('CIUDAD:', 10, 65);
    doc.text(city, 30, 65);
    doc.text('CLIENTE:', 10, 70);
    doc.text(clientCode, 30, 70);

    if (i < data.length - 1) {
      doc.addPage();
    }
  }

  // Descargar el PDF
  doc.save('voucher.pdf');
};